<template>
    <layout class="wrapper article-list">
        <!-- 面包屑 -->
        <div class="container mobile-bread mt15 flex x-left">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/'}">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/journal-article'}">期刊论文</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/meetpaper-list'}">会议列表</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:''}">论文列表</router-link>
                </a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div v-if="subjectList.length" class="container mt10">
            <div class="filter-content pb5">
                <filter-tab title="类别"  :list="subjectList" :isShowMore="true" @clickItem="handleFilterEvent('subjectIndex',$event)"></filter-tab>
            </div>
        </div>
        <div class="container flex mt20">
            <div class="meeting-report">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="interview-list" v-if="paperList.length">
                        <div v-for="(item,index) in paperList" :key="index" class="paper-list pb20 t-l" :class="{'mt20': index != 0}" @click="goArticleDetail(item.id)">
                            <p class="fs16 color333 strong mb10">{{item.title}}</p>
                            <div class="flex x-left color333 fs12 row-1 mb10" v-if="item.real_name || item.org_cnname">
                                作者：
                                <span v-if="item.real_name" class="mr10"><span class="color333">{{item.real_name}}</span></span>
                                <span v-if="item.org_cnname" class="color999">{{item.org_cnname}}</span>
                            </div>
                            <p v-if="item.keywords" class="fs12 color333 mb10">关键词：{{item.keywords}}</p>
                            <div class="flex x-between color999 fs12">
                                <span v-if="item.cn_title" class="fs12 color333 mb5 row-1">来源：{{item.cn_title}}</span>
                                <span class="ml10 t-r" style="width:100px;"><i class="med med-yanjing fs14 mr5"></i>{{item.hits}}</span>
                            </div>
                        </div>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!paperList.length && !loading" />
                </a-spin>
                <div class="t-c mt30" v-if="approiCount > 0">
                    <a-pagination :total="approiCount" v-model:current="paperlistParams.page" :page-size="paperlistParams.limit" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="meeting-right">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index';
import layoutRight from '@/components/layout/layout-right';
import filterTab from '@/components/filter-tab.vue';
import acrossMediaItem from '@/components/across-media-item';
import storage from 'store'
export default {
    name: 'index',
    components: {
        layout,
        layoutRight,
        filterTab,
        acrossMediaItem
    },
    data() {
        return {
            meeting_id: this.$route.query.id,
            paperlistParams: {
                meeting_id: this.$route.query.id,
                page: 1,
                limit: 10,
            },
            approiCount: 0,
            paperList: [],
            subjectIndex: -1,
            subjectList: [],
            layoutRightConfig: {
                // 本月会议
                meeting: {
                    show: true, // 是否显示
                    title: '本月会议', // 标题
                    methods: 'get',
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 6,
                        is_now_year_meeting: 1,
                        subject_ids: storage.get('subjectIds') || ''
                    },   // 参数条件
                },
                // 推荐直播
                live: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '推荐直播', // 标题
                    data: {
                        page: 1,
                        limit: 3
                    },   // 参数条件
                },
                // 推荐视频
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '最新回放', // 标题
                    data: {
                        page: 1,
                        PageSize: 4
                    },   // 参数条件
                },
            },
            loading: true,
        }
    },
    created() {
        this.getAbstractClass()
        this.getMeetingPaper()
    },
    methods: {
        // 文章列表
        getMeetingPaper() {
            this.loading = true
            this.request.post('GetMeetingPaper', this.paperlistParams).then(res => {
                this.approiCount = parseInt(res.data.count)
                this.paperList = res.data.list
                this.loading = false
            })
        },
        // 获取全部类别 （需要token）
        getAbstractClass() {
            this.request.post('AbstractClass', { meeting_id: this.meeting_id }).then(res => {
                if (res.data) {
                    this.subjectList = res.data.abstract_class
                }
            })
        },
        // 学科切换
        handleFilterEvent(key, e) {
            key && (this[key] = e);
            if (this.subjectIndex != -1) {
                this.paperlistParams.class_id = this.subjectList[this.subjectIndex].id
                this.paperlistParams.page = 1
                this.getMeetingPaper()
            } else {
                delete this.paperlistParams.class_id
                this.getMeetingPaper()
            }
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0, 0);
            this.paperlistParams.page = page
            this.getMeetingPaper()
        },
        goArticleDetail(id) {
            this.$router.push({
                path: '/article-detail',
                query: {
                    id,
                    meeting_id: this.meeting_id
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.filter-condition {
    background: #f2f4fa;
    overflow: hidden;
    height: 8px;

    &.active {
        height: 50px;
        line-height: 50px;
    }
}

.meeting-report {
    width: calc(66.66% - 30px);

    .interview-list {
        min-height: 500px;
        .paper-list{
            cursor: pointer;
            border-bottom: 1px dashed #d8d8d8;
        }
    }
}


.meeting-right {
    width: 33.33%;
}

@media screen and (max-width:768px){
    .article-list{
        .container{
            display: block;
            padding:0 10px;
        }
        .mobile-bread{
            display: flex;
        }
        .filter-content{
            width:calc(100% + 20px);
            margin-left:-10px;
        }
        .meeting-right{
            width: 100%;
            padding-top:20px;
        }
        .meeting-report{
            width: 100%;
        }
    }
}
</style>
